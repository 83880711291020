import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  Row,
  Tile,
} from "carbon-components-react";
import { Save16, Undo16 } from "@carbon/icons-react";

import useSWR from "swr";

import { URL, Fetcher as F } from "../../api";
import * as S from "../../utils/schema";
import { SchemaEditor } from "../../components/Editor";
import { SchemaContext, UserContext } from "../../context";
import { useUserData } from "../../hooks";
import { FoodVendorRow } from "../../components/Viewer";

const EditorWrapper = styled.div`
  margin: 16px;
`;

export const FoodVendorHistoryCreateOrEdit = () => {
  const { hid, fvhid } = useParams();

  const {
    token: { access },
  } = useContext(UserContext);

  const navigate = useNavigate();
  const user = useUserData();

  const { changeFoodVendorHistory: schema } = useContext(SchemaContext) || {};

  const isEdit = !!fvhid;

  const [puerperaDisplay, setPuerperaDisplay] = useState("產婦");
  const [currentFoodVendor, setCurrentFoodVendor] = useState();
  const [currentBRS, setCurrentBRS] = useState();

  const housingURL = `${URL.housing}${hid}/?expand=puerperaNote.puerpera,bookRoom.current_brs.room.center,bookRoom.latest_brs.room.center,foodvendor`;
  const { data: housingData } = useSWR(access && [housingURL], F.withToken);

  const changeFVHURL = `${URL.changeFoodVendorHistory}?expand=foodvendor.center&limit=1`;
  const { data: rawFoodVendorData } = useSWR(
    access && [changeFVHURL],
    F.withToken
  );

  const historyURL = `${URL.changeFoodVendorHistory}${fvhid}`;
  const { data: historyData } = useSWR(
    isEdit ? access && [historyURL] : null,
    F.withToken
  );

  const [isSending, setIsSending] = useState(false);

  const initialChangeFoodVendorHistory = useMemo(
    () => S.createInitialData(schema),
    [schema]
  );

  const [changeFoodVendorHistory, setChangeFoodVendorHistory] = useState(
    initialChangeFoodVendorHistory
  );

  useEffect(() => {
    if (!rawFoodVendorData || rawFoodVendorData == null) {
      return;
    }
    // 找到 start_date 是空的，為第一個
    const findFirst = (fv) => fv.start_date === null;
    var firstFV = rawFoodVendorData.find(findFirst);
    if (firstFV === undefined) {
      return;
    }
    setCurrentFoodVendor(firstFV.foodvendor);
  }, [rawFoodVendorData]);

  useEffect(() => {
    if (!housingData || housingData == null) {
      return;
    }

    const puerpera = housingData?.puerperaNote?.puerpera ?? {};

    const cc = housingData?.bookRoom.current_brs.room.center ?? {};
    const lc = housingData?.bookRoom.latest_brs.room.center ?? {};
    var center = undefined;
    if (cc.name !== undefined) {
      center = cc;
    } else if (lc.name !== undefined) {
      center = lc;
    }
    const center_name = center !== undefined ? `(${center.name})` : "";

    setPuerperaDisplay(`${center_name} ${puerpera.name}`);

    const cbrs = housingData?.bookRoom.current_brs;
    const lbrs = housingData?.bookRoom.latest_brs;
    const brs_id =
      cbrs.id !== undefined && cbrs.id !== null ? cbrs.id : lbrs.id;
    setCurrentBRS(brs_id);
  }, [housingData]);

  useEffect(() => {
    if (!historyData) {
      return;
    }
    setChangeFoodVendorHistory(historyData);
  }, [historyData]);

  const isHistoryReady = S.createSimpleValidator(schema);
  const isReady = useMemo(
    () => isHistoryReady(changeFoodVendorHistory),
    [isHistoryReady, changeFoodVendorHistory]
  );

  const handleCancel = useCallback(async () => {
    navigate(`/housing/foodVendor/${hid}`);
  }, [navigate, hid]);

  const handleSubmit = useCallback(async () => {
    if (!isReady) return;
    if (isSending) return;

    const { id, ...o } = changeFoodVendorHistory;
    const payload = { ...o };

    setIsSending(true);
    if (isEdit) {
      await F.put(`${URL.changeFoodVendorHistory}${id}/`, payload);
    } else {
      await F.post(`${URL.changeFoodVendorHistory}`, payload);
    }
    setIsSending(false);
    navigate(`/housing/foodVendor/${hid}`);
  }, [
    navigate,
    hid,
    isReady,
    isEdit,
    isSending,
    setIsSending,
    changeFoodVendorHistory,
  ]);

  const isEditTitle = isEdit ? "編輯" : "新增";
  const storeButtonTitle = isEdit ? "儲存" : "新增";

  return (
    <Content className="foodVendorReason-createOrEdit">
      <Breadcrumb>
        <BreadcrumbItem href="">住房管理</BreadcrumbItem>
        <BreadcrumbItem href={`/housing/${hid}`}>
          {puerperaDisplay}
        </BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          膳食供廠商
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>{isEditTitle}退餐原因</Tile>

      <FoodVendorRow
        puerpera={housingData?.puerperaNote?.puerpera ?? {}}
        foodVendor={currentFoodVendor}
      />

      <Grid>
        <Column>
          <EditorWrapper>
            <SchemaEditor
              fields={schema.fields}
              excludes={[
                "id",
                "created",
                "modified",
                "employee_id",
                "housing",
                "bookroomschedule",
                "activate_date",
                "deactivate_date",
                "status",
              ]}
              data={changeFoodVendorHistory}
              onChange={(x) => {
                if (!user) return;
                setChangeFoodVendorHistory({
                  ...x,
                  housing: hid,
                  bookroomschedule: currentBRS,
                  employee_id: user.employee_id,
                });
              }}
            />
          </EditorWrapper>
        </Column>

        <Column>
          <Grid>
            <Row>
              <Column sm={{ span: 1, offset: 1 }}>
                <Button
                  kind="secondary"
                  size="small"
                  renderIcon={Undo16}
                  onClick={handleCancel}
                >
                  取消
                </Button>
              </Column>
              <Column>
                <Button
                  disabled={!isReady}
                  kind="primary"
                  size="small"
                  renderIcon={Save16}
                  onClick={handleSubmit}
                >
                  {storeButtonTitle}
                </Button>
              </Column>
            </Row>
          </Grid>
        </Column>
      </Grid>
    </Content>
  );
};
