import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Content,
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
  Modal,
} from "carbon-components-react";
import { Add16, Edit20, EditOff20 } from "@carbon/icons-react";
import dayjs from "dayjs";
import useSWR, { useSWRConfig } from "swr";

import { URL, Fetcher as F } from "../../api";
import { DateTimeFormat } from "../../utils/datetime";
import { FoodVendorRow } from "../../components/Viewer";
import { SchemaContext, UserContext } from "../../context";
import { SchemaEditor } from "../../components/Editor";
import { useUserData } from "../../hooks";

const headers = [
  {
    key: "start_date",
    name: "預約開始日期",
  },
  {
    key: "end_date",
    name: "預約結束日期",
  },
  {
    key: "meal_breakfirst",
    name: "早餐",
  },
  {
    key: "meal_launch",
    name: "午餐",
  },
  {
    key: "meal_dinner",
    name: "晚餐",
  },
  {
    key: "employee_name",
    name: "操作人員",
  },
  {
    key: "actions",
    name: "編輯/取消",
  },
];

export const StopMealsHistory = () => {
  const { hid } = useParams();

  const {
    token: { access },
  } = useContext(UserContext);

  const [puerperaDisplay, setPuerperaDisplay] = useState("產婦");
  const [currentFoodVendor, setCurrentFoodVendor] = useState();

  const stopMealsURL = `${URL.stopMealsHistory}?expand=employee,foodvendor.center,start_meals&status=1`;
  const { data: stopMealsHistoryData = [] } = useSWR(
    access && [stopMealsURL],
    F.withToken
  );

  const changeFVHURL = `${URL.changeFoodVendorHistory}?expand=foodvendor.center&limit=1`;
  const { data: rawFoodVendorData } = useSWR(
    access && [changeFVHURL],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const housingURL = `${URL.housing}${hid}/?expand=puerperaNote.puerpera,bookRoom.current_brs.room.center`;
  const { data: housingData } = useSWR(access && [housingURL], F.withToken, {
    revalidateOnFocus: false,
  });

  const isLoading = !stopMealsHistoryData && !housingData;

  useEffect(() => {
    if (!housingData || housingData == null) {
      return;
    }

    const puerpera = housingData?.puerperaNote?.puerpera ?? {};
    const center = housingData?.bookRoom.current_brs.room.center ?? "";
    setPuerperaDisplay(`(${center.name}) ${puerpera.name}`);
  }, [housingData]);

  useEffect(() => {
    if (!rawFoodVendorData || rawFoodVendorData == null) {
      return;
    }
    // 找到 start_date 是空的，為第一個
    const findFirst = (fv) => fv.start_date === null;
    var firstFV = rawFoodVendorData.find(findFirst);
    if (firstFV === undefined) {
      return;
    }
    setCurrentFoodVendor(firstFV.foodvendor);
  }, [rawFoodVendorData]);

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="">住房管理</BreadcrumbItem>
        <BreadcrumbItem href={`/housing/${hid}`}>
          {puerperaDisplay}
        </BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          預約停餐
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>預約停餐</Tile>

      <FoodVendorRow
        puerpera={housingData?.puerperaNote?.puerpera ?? {}}
        foodVendor={currentFoodVendor}
      />

      {isLoading ? (
        <DataTableSkeleton showHeader={false} />
      ) : (
        <StopMealsHistoryTable housing={hid} items={stopMealsHistoryData} />
      )}
    </Content>
  );
};

function StopMealsHistoryTable({ housing, items }) {
  const { stopMealsHistory: schema } = useContext(SchemaContext) || {};
  const {
    token: { access },
  } = useContext(UserContext);
  const user = useUserData();
  const { mutate } = useSWRConfig();

  const cleanData = items.map(({ employee, stop_meals = [], ...other }) => {
    return {
      ...other,
      meal_breakfirst: stop_meals.includes(1) ? "O" : "X",
      meal_launch: stop_meals.includes(2) ? "O" : "X",
      meal_dinner: stop_meals.includes(3) ? "O" : "X",
      employee_name: employee?.name ?? "-",
    };
  });

  const [stopMealsHistory, setStopMealsHistory] = useState();

  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [currentHistoryID, setCurrentHistoryID] = useState(0);
  const [cancelModalStatus, setCancelModalStatus] = useState("inactive");

  const [isEditHistory, setIsEditHistory] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState("inactive");

  const isEditTitle = isEditHistory ? "編輯" : "新增";

  const handleCreate = async () => {
    const { id, ...o } = stopMealsHistory;
    const payload = { ...o };

    setEditModalStatus("active");
    await F.post(`${URL.stopMealsHistory}`, payload);
    mutate([URL.stopMealsHistory]);
    setCancelModalStatus("finished");
    setOpenEditModal(false);
  };

  const stopMealsURL = `${URL.stopMealsHistory}${currentHistoryID}`;
  const { data: rawStopMealsHistory } = useSWR(
    isEditHistory ? access && [stopMealsURL] : null,
    F.withToken
  );

  useEffect(() => {
    if (!rawStopMealsHistory || rawStopMealsHistory == null) {
      return;
    }
    setStopMealsHistory(rawStopMealsHistory);
  }, [rawStopMealsHistory]);

  const handleEdit = async () => {
    if (currentHistoryID === 0 || !rawStopMealsHistory) {
      return;
    }

    const { id, ...o } = stopMealsHistory;
    const payload = { ...o };

    setEditModalStatus("active");
    const r = await F.put(
      `${URL.stopMealsHistory}${currentHistoryID}/`,
      payload
    );
    mutate([URL.stopMealsHistory + r.id], r, false);
    setCancelModalStatus("finished");
    setOpenEditModal(false);
  };

  const handleCreateOrEdit = async () => {
    isEditHistory ? handleEdit() : handleCreate();
  };

  const handleCancel = async () => {
    if (currentHistoryID === 0) {
      return;
    }
    setCancelModalStatus("active");
    await F.patch(`${URL.stopMealsHistory}${currentHistoryID}/`, {
      status: 0,
    });
    mutate([URL.stopMealsHistory]);
    setOpenCancelModal(false);
    setCancelModalStatus("finished");
  };

  return (
    <>
      <Modal
        open={openEditModal}
        modalHeading={`${isEditTitle}預約停餐`}
        primaryButtonText="確認"
        secondaryButtonText="取消"
        loadingStatus={editModalStatus}
        onRequestClose={() => setOpenEditModal(false)}
        onSecondarySubmit={() => setOpenEditModal(false)}
        onRequestSubmit={handleCreateOrEdit}
      >
        <SchemaEditor
          fields={schema.fields}
          excludes={[
            "id",
            "created",
            "modified",
            "employee_id",
            "housing",
            "activate_date",
            "deactivate_date",
            "status",
          ]}
          requiredFields={["start_date", "end_date"]}
          data={stopMealsHistory}
          onChange={(x) => {
            if (!user) return;
            setStopMealsHistory({
              ...x,
              housing: housing,
              employee_id: user.employee_id,
            });
          }}
        />
      </Modal>
      <Modal
        open={openCancelModal}
        danger
        modalHeading="您確定要取消預約停餐嗎？"
        modalLabel="取消"
        primaryButtonText="確認"
        secondaryButtonText="取消"
        loadingStatus={cancelModalStatus}
        onRequestClose={() => setOpenCancelModal(false)}
        onSecondarySubmit={() => setOpenCancelModal(false)}
        onRequestSubmit={handleCancel}
      />
      <DataTable rows={cleanData} headers={headers}>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getToolbarProps,
          getTableContainerProps,
        }) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar
              {...getToolbarProps()}
              aria-label="data table toolbar"
            >
              <TableToolbarContent>
                <Button
                  renderIcon={Add16}
                  onClick={() => {
                    setIsEditHistory(false);
                    setOpenEditModal(true);
                  }}
                >
                  新增預約停餐
                </Button>
              </TableToolbarContent>
            </TableToolbar>

            <Table {...getTableProps()} isSortable>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header })}
                      isSortable={header.key !== "actions"}
                    >
                      {header.name}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      const { header = "" } = cell && cell.info;
                      const id = cell.id.split(":")[0];
                      let content;
                      switch (header) {
                        case "start_date":
                        case "end_date":
                        case "created":
                        case "modified":
                          if (cell.value !== null) {
                            content = dayjs(cell.value).format(
                              DateTimeFormat.date
                            );
                          } else {
                            content = cell.value;
                          }
                          break;
                        case "employee_name":
                        case "meal_breakfirst":
                        case "meal_launch":
                        case "meal_dinner":
                          content = cell.value;
                          break;
                        case "actions":
                          content = (
                            <div>
                              <Button
                                kind="ghost"
                                renderIcon={Edit20}
                                iconDescription="編輯"
                                onFocus={() => setCurrentHistoryID(id)}
                                onClick={() => {
                                  setIsEditHistory(true);
                                  setOpenEditModal(true);
                                }}
                              />
                              <Button
                                kind="ghost"
                                renderIcon={EditOff20}
                                iconDescription="取消"
                                onFocus={() => setCurrentHistoryID(id)}
                                onClick={() => setOpenCancelModal(true)}
                              />
                            </div>
                          );
                          break;
                        default:
                          content = <span>?</span>;
                      }
                      return <TableCell key={cell.id}>{content}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </>
  );
}
