import { Link } from "react-router-dom";
import { Column, Grid, Row } from "carbon-components-react";

import { Item } from "./Item";

export const FoodVendorRow = ({
  loading = false,
  puerpera = {},
  foodVendor = {},
}) => {
  const { id = "", name = "" } = puerpera;
  const { name: foodvendor_name = "", center = {} } = foodVendor;

  return (
    <Grid>
      <Row>
        <Column>
          <Item labelText="產婦姓名" showTop={false} loading={loading}>
            {id ? <Link to={`/puerpera/${id}/info`}>{name}</Link> : name}
          </Item>
        </Column>
        <Column>
          <Item labelText="目前膳食供應商" showTop={false} loading={loading}>
            {foodvendor_name ? `(${center.name ?? ""}) ${foodvendor_name}` : ""}
          </Item>
        </Column>
      </Row>
    </Grid>
  );
};
