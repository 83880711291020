import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Column,
  Content,
  Dropdown,
  Grid,
  Tile,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListBody,
  StructuredListRow,
  StructuredListCell,
  StructuredListSkeleton,
} from "carbon-components-react";

import useSWR from "swr";

import { CentersContext } from "../../context";
import { URL, Fetcher as F } from "../../api";
import { useQuery } from "../../hooks";

export const ThreeDayActivity = () => {
  const { list: centerList } = useContext(CentersContext);
  const q = useQuery();
  const date = q.get("_date");

  const [center, setCenter] = useState();

  useEffect(() => {
    // Fix empty centerList when initial, use fisrt center as default
    if (centerList.length === 0) return;
    setCenter(centerList[0]);
  }, [centerList, setCenter]);

  let bookActivityURL = `${URL.book.activity}?center=${center?.id ?? 1}`;
  if (date !== null) {
    bookActivityURL = bookActivityURL.concat("&", `date=${date}`);
  }
  const { data } = useSWR([bookActivityURL], F.withToken);

  const isLoading = !data;

  return (
    <Content className="room-activity">
      <Tile>五日動態表</Tile>

      <Grid>
        <Column md={2}>
          <Dropdown
            id="center-selector"
            titleText="館別"
            label={center?.name ?? ""}
            items={centerList}
            itemToString={(item) => item.name}
            selectedItem={center}
            onChange={(v) => {
              setCenter(v.selectedItem);
            }}
          />
        </Column>
      </Grid>

      {isLoading ? (
        <StructuredListSkeleton />
      ) : (
        <StructuredListWrapper>
          <StructuredListHead>
            <StructuredListRow head>
              {data.headers.map((header) => (
                <StructuredListCell head id={header.key}>
                  {header.name}
                </StructuredListCell>
              ))}
            </StructuredListRow>
          </StructuredListHead>
          <StructuredListBody>
            {data.activities.map((row) => (
              <StructuredListRow>
                {data.headers.map((header) => {
                  let value = "?";
                  switch (header.content_type) {
                    case "bookroom":
                      value = <BookRoomCells list={row[header.key]} />;
                      break;
                    case "empty":
                      value = <EmptyRoomCells list={row[header.key]} />;
                      break;
                    case "string":
                    default:
                      value = row[header.key];
                      break;
                  }
                  return <StructuredListCell>{value}</StructuredListCell>;
                })}
              </StructuredListRow>
            ))}
          </StructuredListBody>
        </StructuredListWrapper>
      )}
    </Content>
  );
};

const BookRoomCells = ({ list }) => {
  return (
    <Grid>
      {list.map((v) => {
        return (
          <Column>
            <Link to={`/bookroom/${v.bookroom_id}`}>{v.display}</Link>
          </Column>
        );
      })}
    </Grid>
  );
};

const EmptyRoomCells = ({ list }) => {
  return (
    <Grid>
      {list.map((v) => {
        return <Column>{v.name}</Column>;
      })}
    </Grid>
  );
};
