import { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Column,
  Content,
  DataTable,
  DataTableSkeleton,
  DatePicker,
  DatePickerInput,
  Grid,
  Pagination,
  Row,
  Search,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
} from "carbon-components-react";
import { Add16, AddFilled20, Search16 } from "@carbon/icons-react";

import useSWR from "swr";
import { URL, Fetcher as F } from "../../api";
import {
  defaultPageSize,
  defaultPageSizeOptions,
} from "../../components/Viewer";

import dayjs from "dayjs";
import { DateTimeFormat } from "../../utils/datetime";

const bookroomHeaders = [
  {
    key: "created",
    name: "建檔時間",
  },
  {
    key: "contract_number",
    name: "合約編號",
  },
  {
    key: "name",
    name: "產婦",
  },
  {
    key: "mobile_phone",
    name: "產婦手機",
  },
  {
    key: "phone",
    name: "產婦電話",
  },
  {
    key: "actions",
    name: "訂房/排房",
  },
];

const customerBookVisitHeaders = [
  {
    key: "created",
    name: "建檔時間",
  },
  {
    key: "name",
    name: "產婦",
  },
  {
    key: "mobile_phone",
    name: "產婦手機",
  },
  {
    key: "phone",
    name: "產婦電話",
  },
  {
    key: "actions",
    name: "預約參訪",
  },
];

const housingHeaders = [
  {
    key: "checkin_date",
    name: "入住日",
  },
  {
    key: "checkout_date",
    name: "退房日",
  },
  {
    key: "contract_number",
    name: "合約編號",
  },
  {
    key: "name",
    name: "產婦",
  },
  {
    key: "mobile_phone",
    name: "產婦手機",
  },
  {
    key: "phone",
    name: "產婦電話",
  },
  {
    key: "actions",
    name: "訂房/排房",
  },
];

const headerWithStatus = (isHousing, isCustomerBookVisit) => {
  if (isHousing) {
    return housingHeaders;
  } else if (isCustomerBookVisit) {
    return customerBookVisitHeaders;
  }
  return bookroomHeaders;
};

const createQueryString = ({
  offset,
  limit,
  since,
  until,
  keyingKeyword,
  isHousing,
  keyingContractNumber,
}) => {
  const params = new URLSearchParams();
  params.append("expand", "employee,last_contract");
  if (!!offset) {
    params.append("offset", offset);
  }
  if (!!limit) {
    params.append("limit", limit);
  }
  if (isHousing) {
    params.append("in_ten_month", "true");
  }
  if (!!since) {
    const _since = dayjs(since).format(DateTimeFormat.date);
    if (isHousing) {
      params.append("checkin_date_after", _since);
      params.append("checkout_date_after", _since);
    } else {
      params.append("since", _since);
    }
  }
  if (!!until) {
    const _until = dayjs(until).format(DateTimeFormat.date);
    if (isHousing) {
      params.append("checkin_date_before", _until);
      params.append("checkout_date_before", _until);
    } else {
      params.append("until", _until);
    }
  }
  if (!!keyingKeyword) {
    params.append("search", keyingKeyword);
  }
  if (isHousing !== undefined && isHousing === true) {
    // 查詢訂房種類為 排房
    params.append("book_type", 2);
  }
  if (keyingContractNumber !== undefined) {
    params.append("contract_number", keyingContractNumber);
  }
  return params.toString();
};

export const SelectPuerpery = ({
  isHousing = false,
  isCustomerBookVisit = false,
  searchString = null,
  hadDefautSearchValue = false,
  targetBlock,
  actionButtonTitle,
  actionButtonLink,
}) => {
  const [limit, setLimit] = useState(defaultPageSize);
  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;
  const [since, setSince] = useState();
  const [until, setUntil] = useState();
  const [keyingKeyword, setKeyingKeyword] = useState(searchString);
  const [keyingContractNumber, setKeyingContractNumber] = useState();
  const [hadDefaultSearch, setHadDefaultSearch] = useState(
    !hadDefautSearchValue
  );

  const queryString = useMemo(
    () =>
      createQueryString({
        offset,
        limit,
        since,
        until,
        keyingKeyword,
        isHousing,
        keyingContractNumber,
      }),
    [
      offset,
      limit,
      since,
      until,
      keyingKeyword,
      isHousing,
      keyingContractNumber,
    ]
  );

  const [realyQuery, setRealyQuery] = useState(queryString);

  const url = `${URL.puerpera}?${realyQuery}`;
  const { data, error, isValidating } = useSWR([url], F.withToken);

  useEffect(() => {
    if (hadDefaultSearch) return;
    if (searchString && searchString !== "") {
      setKeyingKeyword(searchString);
      setRealyQuery(queryString);
      setHadDefaultSearch(true);
    }
  }, [
    hadDefaultSearch,
    searchString,
    setKeyingKeyword,
    queryString,
    setHadDefaultSearch,
  ]);

  const hasResponse = !isValidating && !error && data;

  return (
    <Content>
      <Tile>
        選擇產婦
        <Grid>
          <Row>
            <Column>
              <DatePicker
                dateFormat="Y/m/d"
                datePickerType="range"
                onChange={([startDate, endDate]) => {
                  setSince(startDate);
                  setUntil(endDate);
                }}
              >
                <DatePickerInput
                  id="selectPuerpera__search__startDate"
                  placeholder="yyyy/mm/dd"
                  labelText={isHousing ? "入住日" : "起始日期"}
                />
                <DatePickerInput
                  id="selectPuerpera__search__endDate"
                  placeholder="yyyy/mm/dd"
                  labelText={isHousing ? "退房日" : "結束日期"}
                />
              </DatePicker>
            </Column>
            <Column>
              <Search
                labelText="搜尋合約編號"
                placeholder="合約編號"
                onChange={(e) => {
                  const v = e.target.value;
                  setKeyingContractNumber(v);
                }}
              />
            </Column>
            <Column>
              <Search
                size="lg"
                labelText="搜尋"
                placeholder="產婦姓名、產婦電話"
                value={keyingKeyword}
                onChange={(e) => {
                  const v = e.target.value;
                  setKeyingKeyword(v);
                }}
              />
            </Column>
            <Column>
              <Button
                renderIcon={Search16}
                iconDescription="搜尋"
                hasIconOnly
                onClick={() => {
                  setRealyQuery(
                    createQueryString({
                      offset,
                      limit,
                      since,
                      until,
                      keyingKeyword,
                      isHousing,
                      keyingContractNumber,
                    })
                  );
                }}
              ></Button>
            </Column>
          </Row>
        </Grid>
      </Tile>
      {hasResponse ? (
        <PuerperaTable
          page={page}
          offset={offset}
          limit={limit}
          puerperas={data}
          isHousing={isHousing}
          isCustomerBookVisit={isCustomerBookVisit}
          onPageChange={({ page, pageSize }) => {
            // offset and limit will not be update until the next event loop iteration
            const limit = pageSize;
            const offset = (page - 1) * limit;
            setPage(page);
            setLimit(limit);
            setRealyQuery(
              createQueryString({
                offset,
                limit,
                since,
                until,
                keyingKeyword,
                isHousing,
                keyingContractNumber,
              })
            );
          }}
          targetBlock={targetBlock}
          tableActionButtonTitle={actionButtonTitle}
          tableActionButtonLink={actionButtonLink}
        />
      ) : (
        <DataTableSkeleton
          headers={headerWithStatus(isHousing, isCustomerBookVisit)}
        />
      )}
    </Content>
  );
};

function PuerperaTable({
  page,
  pageSize,
  puerperas,
  onPageChange,
  targetBlock,
  isHousing,
  isCustomerBookVisit,
  tableActionButtonTitle,
  tableActionButtonLink,
}) {
  const total = (puerperas && puerperas.count) || 0;
  const data = (puerperas && puerperas.results) || [];

  const cleanData = data.map(({ last_contract, ...other }) => {
    return {
      ...other,
      contract_number: last_contract?.contract_number ?? "",
    };
  });

  const pageProps = {
    disabled: !puerperas,
    page: page,
    totalItems: total,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  };

  return (
    <DataTable
      rows={cleanData}
      headers={headerWithStatus(isHousing, isCustomerBookVisit)}
    >
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        getToolbarProps,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          {tableActionButtonTitle && tableActionButtonLink && (
            <TableToolbar {...getToolbarProps()}>
              <TableToolbarContent>
                <Button renderIcon={Add16} as={Link} to={tableActionButtonLink}>
                  {tableActionButtonTitle}
                </Button>
              </TableToolbarContent>
            </TableToolbar>
          )}

          <Table {...getTableProps()} isSortable>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    key={header.key}
                    {...getHeaderProps({ header })}
                    isSortable={header.key !== "actions"}
                  >
                    {header.name}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    const { header = "" } = cell && cell.info;
                    let content;
                    const pID = cell.id.split(":")[0];
                    const handleTarget = function (e) {
                      e.preventDefault();
                      targetBlock(pID);
                    };
                    const raw = data.filter((x) => x.id === pID);
                    const bookroomID =
                      raw[0] !== undefined && raw[0].bookroom_id;
                    switch (header) {
                      case "mobile_phone":
                      case "phone":
                      case "contract_number":
                        content = cell.value;
                        break;
                      case "name":
                        content = isHousing ? (
                          <Button
                            as={Link}
                            kind="ghost"
                            to={`/bookroom/${bookroomID}`}
                          >
                            {cell.value}
                          </Button>
                        ) : (
                          cell.value
                        );
                        break;
                      case "employee":
                        content = cell.value.name;
                        break;
                      case "created":
                      case "checkin_date":
                      case "checkout_date":
                        content = dayjs(cell.value).format(DateTimeFormat.date);
                        break;
                      case "actions":
                        content = (
                          <Button
                            kind="ghost"
                            renderIcon={AddFilled20}
                            onClick={handleTarget}
                          />
                        );
                        break;
                      default:
                        content = <span>?</span>;
                    }
                    return <TableCell key={cell.key}>{content}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination {...pageProps} />
        </TableContainer>
      )}
    </DataTable>
  );
}
