function hasValue(v) {
  return v !== undefined && v !== null && v !== "";
}

export function createSimpleValidator(withFields = {}) {
  const { fields = [], moreRequriedkeys = [] } = withFields;
  let keys = fields.filter((field) => field.required).map((field) => field.key);
  keys = keys.concat(moreRequriedkeys);
  return function (obj) {
    if (!obj) return false;
    return keys.map((key) => obj[key]).every(hasValue);
  };
}

export function createInitialData(withFields = {}) {
  const { fields = [] } = withFields;
  let result = {};
  for (let field of fields) {
    const { default: defaultValue } = field.schema;
    if (defaultValue !== undefined) {
      result[field.key] = defaultValue;
    }
  }
  return result;
}
